// Fonts
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800;900&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body{
    &.locked{
        height: 100vh;
        overflow: hidden;
    }
}
