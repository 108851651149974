// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$primary-black: #131719;
$light-grey: #23272B;
$orange: #FF5B00;
$blue: #4cc5f3;
$red: #FF2D3A;

$transition: 600ms cubic-bezier(0.535, 0.000, 0.000, 1.000);
